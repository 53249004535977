<template>
  <b-card>
    <b-form-group label="Adı Soyadı">
      <b-form-input
        v-model="getFormData.text1"
        placeholder="Adı Soyadı"
      />
    </b-form-group>
    <b-form-group label="Yıl">
      <v-select
        v-model="getFormData.text2"
        :options="years"
        placeholder="Yıl"
      />
    </b-form-group>
    <b-button
      variant="danger"
      size="sm"
      @click="getSampleData"
    >
      <FeatherIcon icon="CheckIcon" />
      Örnek Metin Getir
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Seniority',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      years: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      ],
    }
  },
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
    sampleData() {
      return this.$store.getters['hrNotificationTypes/getHr_notification_type']
    },
  },
  watch: {
    sampleData(val) {
      if (!this.getFormData.text1) {
        this.getFormData.text1 = val.sample_text1
      }
      if (!this.getFormData.text2) {
        this.getFormData.text2 = val.sample_text2
      }
    },
  },
  methods: {
    getSampleData() {
      this.$store.dispatch('hrNotificationTypes/hr_notification_typeView', this.getFormData.id_com_hr_notification_types)
    },
  },
}
</script>
