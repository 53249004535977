<template>
  <b-row>
    <b-col
      xs="12"
      md="8"
    >
      <b-card>
        <b-form-group label="Adı Soyadı">
          <b-form-input
            v-model="getFormData.text1"
            placeholder="Adı Soyadı"
          />
        </b-form-group>
        <b-form-group label="Görevi">
          <b-form-input
            v-model="getFormData.text2"
            placeholder="Görevi"
          />
        </b-form-group>
        <b-form-group label="Kısa Özgeçmişi">
          <quill-editor
            v-model="getFormData.text3"
            :options="editorOption"
          >
            <div
              id="toolbar"
              slot="toolbar"
              class="bg-white"
            >
              <!-- Add a bold button -->
              <button class="ql-bold">
                Bold
              </button>
              <button class="ql-italic">
                Italic
              </button>
            </div>
          </quill-editor>
        </b-form-group>
        <b-button
          variant="danger"
          size="sm"
          @click="getSampleData"
        >
          <FeatherIcon icon="CheckIcon" />
          Örnek Metin Getir
        </b-button>
      </b-card>
    </b-col>
    <b-col
      xs="12"
      md="4"
    >
      <b-card>
        <b-card-title>Personel Fotoğrafı</b-card-title>
        <div class="text-center cropped-image">
          <img
            v-if="getFormData.image"
            :src="getFormData.image"
            alt="Personel Fotoğrafı"
          >
          <div
            v-else
            class="photo-container text-center"
          >
            <FeatherIcon
              icon="UserIcon"
              size="30"
            />
            <div>Personel Fotoğrafı</div>
          </div>
        </div>
        <div class="text-center mt-2">
          <b-button
            v-b-modal.modal-lg
            variant="outline-primary"
          >
            Fotoğraf Seç
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Kırp"
      centered
      size="lg"
      title="Personel Fotoğrafı Ayarla"
      @ok="cropImage"
    >
      <div>
        <b-row>
          <b-col
            cols="8"
            class="cropper-area"
          >
            <b-card-title>Fotoğraf Seçimi</b-card-title>
            <input
              ref="input"
              type="file"
              name="image"
              accept="image/*"
              @change="setImage"
            >
            <div class="img-cropper">
              <vue-cropper
                v-if="imgSrc"
                ref="cropper"
                :aspect-ratio="280/340"
                :src="imgSrc"
                preview=".preview"
              />
              <div v-else>
                <div class="photo-container text-center">
                  <FeatherIcon
                    icon="UserIcon"
                    size="30"
                  />
                  <div>Personel Fotoğrafı</div>
                </div>
              </div>
              <div class="text-center mt-2">
                <b-button
                  variant="primary"
                  @click.prevent="showFileChooser"
                >
                  <FeatherIcon icon="UploadIcon" /> Fotoğraf Yükle</b-button>
              </div>
              <div
                v-if="imgSrc"
                class="text-center mt-2 actions"
              >
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="zoom(0.2)"
                >
                  <FeatherIcon icon="PlusIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="zoom(-0.2)"
                >
                  <FeatherIcon icon="MinusIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="move(-10, 0)"
                >
                  <FeatherIcon icon="ChevronLeftIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="move(10, 0)"
                >
                  <FeatherIcon icon="ChevronRightIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="move(0, -10)"
                >
                  <FeatherIcon icon="ChevronUpIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="move(0, 10)"
                >
                  <FeatherIcon icon="ChevronDownIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="rotate(-90)"
                >
                  <FeatherIcon icon="RotateCcwIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="rotate(90)"
                >
                  <FeatherIcon icon="RotateCwIcon" /></b-button>
                <b-button
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click.prevent="reset"
                >
                  <FeatherIcon icon="RefreshCcwIcon" /></b-button>
              </div>
            </div></b-col>
          <b-col cols="4">
            <b-card-title>Önizleme</b-card-title>
            <div class="preview" />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BCardTitle, BModal, BButton,
} from 'bootstrap-vue'
import VueCropper from 'vue-cropperjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Welcome',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    VueCropper,
    BCardTitle,
    BModal,
    BButton,
    quillEditor,
  },
  data() {
    return {
      imgSrc: '',
      cropImg: '',
      data: null,
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
        placeholder: 'Kısa Özgeçmişi...',
        height: '500px',
      },
    }
  },
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
    sampleData() {
      return this.$store.getters['hrNotificationTypes/getHr_notification_type']
    },
  },
  watch: {
    sampleData(val) {
      if (!this.getFormData.text1) {
        this.getFormData.text1 = val.sample_text1
      }
      if (!this.getFormData.text2) {
        this.getFormData.text2 = val.sample_text2
      }
      if (!this.getFormData.text3) {
        this.getFormData.text3 = val.sample_text3
      }
    },
  },
  methods: {
    getSampleData() {
      this.$store.dispatch('hrNotificationTypes/hr_notification_typeView', this.getFormData.id_com_hr_notification_types)
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.getFormData.image = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4)
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg)
    },
    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
  },
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.cropper-area {
  width: auto;
}
.preview {
  width: 100%;
  height: calc(372px * (280 / 340));
  overflow: hidden;
}
.cropped-image img {
  width: 280px;
}
.photo-container{
  border: 1px dotted #cccccc;
  width: 280px;
  padding-top: 140px;
  padding-bottom: 140px;
  margin:0px auto;
}
</style>
