<template>
  <b-card>
    <b-form-group label="Metin Alanı 1">
      <b-form-textarea
        v-model="getFormData.text1"
        placeholder="Metin Alanı 1"
      />
    </b-form-group>
    <b-button
      variant="danger"
      size="sm"
      @click="getSampleData"
    >
      <FeatherIcon icon="CheckIcon" />
      Örnek Metin Getir
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Leaving',
  components: {
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
  },
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
    sampleData() {
      return this.$store.getters['hrNotificationTypes/getHr_notification_type']
    },
  },
  watch: {
    sampleData(val) {
      if (!this.getFormData.text1) {
        this.getFormData.text1 = val.sample_text1
      }
    },
  },
  methods: {
    getSampleData() {
      this.$store.dispatch('hrNotificationTypes/hr_notification_typeView', this.getFormData.id_com_hr_notification_types)
    },
  },
}
</script>
<style scoped></style>
